var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h1',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.$t('operator_manage.old_title')))])])]),_c('b-card',[_c('b-table',{attrs:{"responsive":"","items":_vm.filteredTableData,"fields":_vm.tableColumns,"show-empty":"","empty-text":_vm.$t('table_columns.empty_text'),"busy":_vm.isLoadingTableData,"hover":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function(ref){
var label = ref.label;
var ref_field = ref.field;
var key = ref_field.key;
var sortable = ref_field.sortable;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(id)",fn:function(data){return [_c('strong',[_vm._v(_vm._s((_vm.paginationData.perPage * (_vm.paginationData.currentPage - 1) || 0) + data.index + 1))])]}},{key:"cell(ref)",fn:function(data){return [_c('b-avatar',{attrs:{"variant":"dark"}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1),_vm._v(_vm._s(" ")+" "+_vm._s(data.item.ref)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mx-0",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'operateurs-details',
            params: {
              operateur_id: data.item.id,
            },
          })}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('button.see')))])],1)]}}])}),_c('div',[_c('PaginationComponent',{attrs:{"table-data":_vm.tableData,"per-page":_vm.paginationData.perPage,"total-rows":_vm.paginationData.total},on:{"change":_vm.onPaginationChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }