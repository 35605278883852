<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex justify-content-between">
        <h1 class="my-auto">{{ $t('operator_manage.old_title') }}</h1>
        <!-- <b-button
          v-if="$can('create', 'etablissement')"
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({ name: 'operateurs-create' })"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Ajouter un établissement</span>
        </b-button> -->
      </div>
      <!--  -->
    </b-card>

    <b-card>
      <b-table responsive :items="filteredTableData" :fields="tableColumns" show-empty
        :empty-text="$t('table_columns.empty_text')" :busy="isLoadingTableData" hover>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <template #cell(id)="data">
          <strong>{{
            (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
            data.index +
            1
          }}</strong>
        </template>
        <template #cell(ref)="data">
          <b-avatar variant="dark"> <feather-icon icon="ImageIcon" /> </b-avatar>{{ " " }}
          {{ data.item.ref }}
        </template>
        <!-- <template #cell(statut)="data">
          <b-badge v-if="data.item.isVerified" variant="success">
            Validé
          </b-badge>
          <b-badge v-if="!data.item.isVerified" variant="warning">
            En attente
          </b-badge>
          <b-badge v-if="data.item.isRejected" variant="danger">
            Rejetté
          </b-badge>
        </template> -->
        <template #cell(actions)="data">
          <b-button variant="primary" class="mx-0" @click="
            $router.push({
              name: 'operateurs-details',
              params: {
                operateur_id: data.item.id,
              },
            })
            ">
            <feather-icon icon="EyeIcon" />
            <span class="text-nowrap"> {{ $t('button.see') }}</span>
          </b-button>
        </template>
      </b-table>
      <div>
        <PaginationComponent :table-data="tableData" :per-page="paginationData.perPage" :total-rows="paginationData.total"
          @change="onPaginationChange" />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BBadge,
  BFormGroup,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import utilsService from "@/services/utils/utils.service";
import { buildFiltersForRequest } from "@/helpers/http-utils";

export default {
  name: "Opérateurs",
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    BBadge,
    BFormGroup,
    vSelect,
    PaginationComponent,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    const departementsList = utilsService.getDepartements();
    const communesList = utilsService.getCommunes();

    return { departementsList, communesList, requiredStoreModules };
  },
  data() {
    return {
      typesEts: [],

      filtersForm: { departement: null, commune: null, secteur_id: null },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        { key: "id", label: "#", sortable: true, thStyle: { width: "5%" } },
        {
          key: "nom_etablissement",
          label: "table_columns.company_name",
          sortable: true,
        },
        {
          key: "departement",
          label: "table_columns.departement",
          sortable: true,
        },
        {
          key: "commune",
          label: "table_columns.commune",
          sortable: true,
        },
        {
          key: "email_etablissement",
          label: "table_columns.email",
          sortable: false,
        },
        {
          key: "telephone_etablissement",
          label: "table_columns.phone",
          sortable: false,
        },
        {
          key: "actions",
          label: "table_columns.actions",
        },
      ],
      isLoadingTableData: false,
      loadingExport: false,
    };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        this.getListEstablishments();
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListEstablishments();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListEstablishments();
      },
    },
  },
  mounted() {
    this.getListTypesEtablissement();
  },

  methods: {
    ...mapActions("etablissements", {
      action_createEtablissement: "createEtablissement",
      action_findEtablissement: "findEtablissement",
      action_searchEtablissement: "searchEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
      action_exportEtablissements: "exportEtablissements",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    // -------------------------------------------------------------
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val };
    },
    getListEstablishments() {
      this.isLoadingTableData = true;
      const reqOptions = {
        ...this.filtersForm,
        per_page: this.paginationData.perPage,
        page: this.paginationData.currentPage,
      };
      // console.log("reqOptions: ⭕️", reqOptions);
      // return;
      this.action_searchEtablissement(reqOptions)
        .then((response) => {
          // console.log("response::: ⭕️", response, response.status);
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.paginationData.total = response.data.total;
            this.isLoadingTableData = false;
            // ---------------------------------------------------------
            if (this.paginationData.metaData.from == 0)
              this.paginationData.metaData.from += 1;
            else {
              this.paginationData.metaData.from =
                this.paginationData.perPage * this.paginationData.currentPage -
                this.paginationData.perPage;
            }
            this.paginationData.metaData.to =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage +
              this.tableData.length;
            // ---------------------------------------------------------
          }
        })
        .catch((err) => {
          this.isLoadingTableData = false;
        });
    },
    uploadData(action) {
      this.loadingExport = true;
      // if( action === 'allData'){
      //   this.query.filter.type = []
      //   this.query.filter.text = []
      // }
      let params = {};
      if (action === "filteredData") {
        const filters = buildFiltersForRequest(this.filtersForm);
        params = {
          filter: filters,
        };
      }

      this.action_exportEtablissements({ params: { ...params } })
        .then(async (res) => {
          if (res.status == 200) {
            this.loadingExport = false;
            try {
              const blob = res.data;
              const newBlob = new Blob([blob]);
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = objUrl;
                link.download = "inscriptions.csv";
                link.click();
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);
              }
            } catch (error) {
              console.log("error: ", error);
            }
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          if (res.status === 200) this.typesEts = res.data.data || [];
        })
        .catch((err) => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;

  a {
    text-transform: uppercase;
  }
}

.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
